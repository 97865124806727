import { useState, createContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Homepage from './components/Homepage';
import Dashboard from './components/Dashboard';
import BNBRide from './artifacts/contracts/BNBRide.sol/RideBNB.json'
import Fee from './artifacts/contracts/Fee.sol/Fee.json'
import { message } from 'antd';
import { ethers } from 'ethers';
import Teams from './components/Teams';
import BinaryTree from './components/BinaryTree';
import Admin from './components/Admin';
import Red from './components/Red';
import Downline from './components/Downline'
import Test from './components/Test';
import { Modal, Button } from "antd";
import { ThreeDots } from 'react-loader-spinner';
import Royalty from './components/Royalty';

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

const AppState = createContext();

function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
    setIsLoading(false);
    setShowMessage(false);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setIsLoading(false);
    setShowMessage(false);
  };

  const handleShowData = () => {
    setIsLoading(true);
    setShowMessage(false);

    setTimeout(() => {
      setIsLoading(false);
      setShowMessage(true);
    }, 4000); // Simulate 4 seconds of loading
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {ethereum} = window;
  const navigate = useNavigate();
  const [theme, setTheme] = useState('dark');
  const [change, setChange] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [id, setId] = useState("0");
  const [bal, setBal] = useState(0);
  const [bal2, setBal2] = useState(0);
  const [bal3, setBal3] = useState(0);
  const [shown, setShown] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const ranks = ["Beginner", "Influencer", "Achiever", "Ambassador", "Pioneer", "Mentor", "Champion", "Director", "Titan", "Icon", "Legend", "Emperor", "Conqueror"];
  const contractAddress = "0xae9a7740352Bd4E3f23D7725AcF7D91b8091059D";
  const feeAddress = "0x5173ad4CdC7C46b0d8195E1BcA7ce61b79627ECf";
  const royaltyAddress = "0xd03131B38ef40e0d462D9f77F23F407cde89761E";
  // const usdtAddress = "0xbaC2e6b6d70d68F2F470856125Ed0658251A2F0c";
  const usdtAddress = "0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3";
  const [levels, setLevels] = useState([0,0,0,0,0,0,0,0,0,0,0,0, 0]);
  const [percent, setPercent] = useState([0,0,0,0,0,0,0,0,0,0,0,0, 0]);

  let provider;
  let signer;

  async function getProvider() {
    if(ethereum == null) {
      provider = new ethers.JsonRpcProvider();
    } else {
      provider = new ethers.BrowserProvider(window.ethereum);
      signer = await provider.getSigner();
      let _bal = await provider.getBalance(walletAddress);
      let _bal2 = await provider.getBalance(feeAddress);
      let _bal3 = await provider.getBalance(royaltyAddress);
      setBal(convert(_bal));
      setBal2(convert(_bal2));
      setBal3(convert(_bal3));
    }
  }
  getProvider();

  async function getContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(contractAddress, BNBRide.abi, signer);
  }

  async function getFeeContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(feeAddress, Fee.abi, signer);
  }

  async function getUsdtContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(usdtAddress, erc20Abi, signer);
  }
  
  const staticContract = new ethers.Contract(contractAddress, BNBRide.abi, provider);
  const staticFeeContract = new ethers.Contract(feeAddress, Fee.abi, provider);
  const staticUsdtContract = new ethers.Contract(usdtAddress, erc20Abi, provider);

  useEffect(() => {
    let _theme = localStorage.getItem('theme');
    if(_theme !== null) {
      setTheme(_theme);
    }

    async function getWallet() {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        setWalletAddress(accounts[0]);
      } catch (error) {
        message.error("Install Web3 Wallet");
      }
    }

    getWallet();
  },[])

  useEffect(() => {
    async function getLevels() {
      setLoaded(false);
      let _lvl = await staticContract.getLevels();
      setLevels([0])
      for(let i=0; i<_lvl[0].length; i++) {
        setLevels((prev) => [...prev, Number(ethers.formatEther(_lvl[0][i]))]);
      }
      setPercent([0]);
      for(let i=0; i<_lvl[1].length; i++) {
        setPercent((prev) => [...prev, Number(_lvl[1][i])]);
      }
      let _id = await staticContract.id(walletAddress);
      if(Number(_id) == 0) {
        setIsModalOpen(false);
      }
      setId(Number(_id));
      // setId(17541);
      // setId(17562);
      // setId(17884);
      setLoaded(true);
    } 
    getLevels();
  },[walletAddress, change])

  const toggle = () => {
    if(theme === 'dark') {
      setTheme('light');
      localStorage.setItem('theme', 'light');
      } else if(theme === 'light') {
        setTheme('dark');
        localStorage.setItem('theme', 'dark');
    }
  }

  const Login = async () => {
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      setWalletAddress(accounts[0]);

      let id = await staticContract.id(accounts[0])
      let zeroAddr = 0;
      if(Number(id) == zeroAddr) {
        message.error("User Not Registered");
      } else {
        message.success("Logged In");
        navigate('/dashboard');
      }
    } catch (error) {
      message.error("Install Web3 Wallet");
    }
  }

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  const convert = (val) => {
    return Number(ethers.formatEther(val));
  }

  function getUTCTime(ms) {
    const now = new Date(ms);

    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1; // Months are zero-indexed
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();

    const utcTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} UTC`;
    return utcTime;
  }

  const [isChristmasModalVisible, setIsChristmasModalVisible] = useState(false);

  return (
    <AppState.Provider value={{staticUsdtContract, getUsdtContract, usdtAddress, isModalOpen,formatTime,getUTCTime, staticFeeContract, getFeeContract, loaded, shown, setShown, feeAddress, royaltyAddress, bal, bal2, bal3, id, navigate, percent, levels, ranks, toggle, getContract, theme, Login, walletAddress, change, setChange, staticContract, convert, contractAddress}}>
    <body className={theme}>
      <div className="App">

      <Modal
        open={isChristmasModalVisible}
        footer={null}
        onCancel={() => setIsChristmasModalVisible(false)}
        centered
        width={600}
        bodyProps={{
          background: "#fef3c7", // A soft festive background
          borderRadius: "15px",
          padding: "20px"
        }}
      >
        <div className="flex flex-col items-center text-center">
          <h1 className="text-3xl font-bold text-red-600 mb-4">Happy New Year 2025!</h1>
          <p className="text-lg text-gray-700 mb-6">
          "Cheers to new beginnings and endless possibilities—wishing you a joyous and prosperous New Year 2025!" 🎉🎆
          </p>
          <div className="flex justify-start overflow-x-auto items-center gap-4 mb-6">
            <img
              src="new_year.jpg"
              alt="Christmas 1"
              className="rounded-lg shadow-lg"
            /> 
          </div>
          {/* <Button
            type="primary"
            size="large"
            className="bg-red-500 hover:bg-red-600 border-none text-white rounded-md"
            onClick={() => setIsChristmasModalVisible(false)}
          >
            Close
          </Button> */}
        </div>
      </Modal>

      {/* <div className="z-10 flex justify-center items-center min-h-screen bg-gray-100">  */}
      {/* <div className="z-10 flex justify-center items-center min-h-screen bg-gray-100">  */}
        <Modal
          title={
            <div className="text-2xl font-semibold text-blue-600">
              📢 Important Information
            </div>
          }
          open={isModalOpen}
          okButtonProps={{className: "hidden"}}
          cancelButtonProps={{className: "hidden"}}
          closable={false}
          centered
          width={600}
        >
          <div className='flex justify-between items-center mt-6 mb-3'>
            <a onClick={() => {setIsModalOpen(false)}} className='w-1/2 cursor-pointer flex flex-col justify-center items-center bg-gray-900 bg-opacity-40 blue_blur rounded-sm p-2'>
              <p className='font-semibold text-lg'>RideBNB <span className='text-orange-500 text-xl font-bold'>1.0</span></p>
              <img src='logo192.png' className='h-20' />
            </a>
            <div onClick={() => {setIsModalVisible(true); handleShowData()}} className='w-1/2 cursor-pointer ml-6 flex flex-col justify-center items-center bg-gray-900 bg-opacity-40 blue_blur rounded-sm p-2'>
              <p className='font-semibold text-lg'>RideBNB <span className='text-lime-500 text-xl font-bold'>2.0</span></p>
              <img src='logo192.png' className='h-20' />
            </div>
          </div>
          <div className="text-gray-700">
          <p>
            Dear Riders, We are pleased to inform you that our existing smart
            contract ending with <span className="font-semibold">#12340 (RIDE BNB 1.0)</span> will
            continue to operate as before, though without the <span className="font-semibold">Royalty Bonus</span>.
          </p>
          <p className="mt-2">
            Members who wish to continue with version 1.0 can do so seamlessly,
            as our platform is built for long-term sustainability.
          </p>
          <p className="mt-2">
            <span className="font-semibold">For those transitioning to RIDE BNB 2.0</span>, we ask for
            your patience as we prepare for its launch in the coming weeks. The
            new version will feature significant upgrades, enhanced
            profitability, and improved functionality.
          </p>

          <div className="mt-4">
            <h3 className="font-bold text-gray-800">Key Information:</h3>
            <ul className="list-disc list-inside space-y-2 mt-2">
              <li>
                <span className="font-semibold">Data Migration:</span> All data
                from RIDE BNB 1.0 will be migrated after{" "}
                <span className="font-semibold">4:30 PM UTC on December 19, 2024</span>.
              </li>
              <li>
                <span className="font-semibold">Dual Login Options:</span> Post-transition,
                members will have the option to log in to either RIDE BNB 1.0
                or RIDE BNB 2.0 based on their preferences.
              </li>
            </ul>
          </div>

          <p className="mt-4">
            We will keep you updated on all developments as we move forward.
            Thank you for your continued trust and support!
          </p>
          <p className="mt-4 font-semibold text-right">Warm regards,<br />RIDE BNB Team</p>
        </div>
        </Modal>

      <Modal
        title="Important Notice"
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        {isLoading && (
          <div className="flex flex-col items-center">
            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-blue-700 font-medium">Loading...</p>
          </div>
        )}

        {showMessage && (
          <div className="text-center">
            <h1 className="text-2xl font-bold flex justify-center w-full items-center text-gray-800">Data Loading <ThreeDots color='black' height={10} /></h1>
            <p className="mt-2 text-gray-600">
            The data is estimated to be available by <span className="font-semibold">January 15</span>.
            </p>
            <Button
              onClick={handleCloseModal}
              className="mt-4 bg-blue-600 hover:bg-blue-700 text-white"
            >
              Close
            </Button>
          </div>
        )}
      </Modal> 
      {/* </div> */}
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/team' element={<Teams />} />
          <Route path='/matrix' element={<BinaryTree />} />
          <Route path='/royalty' element={<Royalty />} />
          <Route path='/yellow' element={<Admin />} />
          <Route path='/radius' element={<Red />} />
          <Route path='/downline' element={<Downline />} />
          <Route path='/test' element={<Test />} />
        </Routes>
      </div>
    </body>
    </AppState.Provider>
  );
}

export default App;
export {AppState};
