import {SunOutlined, MoonOutlined} from '@ant-design/icons' 
import { useContext } from 'react'
import { AppState } from '../App'
import Marquee from "react-fast-marquee";
import Register from './Register';

const Homepage = () => {
  const useAppState = useContext(AppState);

  const CorrectSvg = () => (
    <svg
      className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );

  return (
    <div>
      <header className="fixed w-full z-10">
        <nav className="bg-white border-gray-200 py-2.5 dark:bg-[#181a1e] transition-colors duration-1000 shadow-sm">
          <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
            <a href="#" className="flex items-center">
              <img src="logo.png" className="h-12 mr-3" alt="ridebnb" />
              {/* <span className="self-center text-xl font-bold whitespace-nowrap dark:text-white"><span className='text-[#FFE900]'>BNB</span>Ride</span> */}
            </a>
            <div className="flex items-center lg:order-2">
              <button
                onClick={() => useAppState.Login()}
                className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-[#f7f7f8] dark:hover:bg-white focus:outline-none dark:focus:ring-gray-300 dark:text-gray-900"
              >
                Login
              </button>
            </div>
          </div>
        </nav>
      </header>

      <Register />

      <section className="bg-white dark:bg-[#14151A] transition-colors duration-1000">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <p className="max-w-2xl mb-4 text-gray-500 font-bold text-xl dark:text-purple-500">
              EMPOWERING EACH THROUGH GLOBAL REACH
            </p>
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl text-[#FFE900]">
              Building Dreams <br />
              Together.
            </h1>
            <p className="max-w-2xl mb-6 text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-[#c4c5cb]">
              <b>RIDE BNB</b> is a pioneering Web 3.0 platform powered by
              blockchain and smart contracts, designed to empower users on their
              journey towards economic independence and progressive lifestyles.
              By utilizing cutting-edge blockchain technology, BNB Ride ensures
              secure, transparent pathways for community members to achieve
              their personal and professional goals. Our platform fosters a
              collaborative community where success is shared, amplified, and
              accessible to all, driving growth through innovation and support.
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <button
                onClick={() => useAppState.Login()}
                className="text-white bg-pink-500 hover:bg-pink-600 focus:ring-4 focus:ring-pink-300 font-medium rounded-lg text-sm px-6 lg:px-8 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-[#f7f7f8] dark:text-black dark:hover:bg-white focus:outline-none dark:focus:ring-gray-300"
              >
                Start Now
              </button>
            </div>
          </div>
          <div className="lg:mt-0 lg:col-span-5 lg:flex">
            <img src="hero.png" alt="hero image" className="h-[400px]" />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-[#1e2026]">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col-reverse">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                SEAMLESS AND SMOOTH ACCESS —
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Experience effortless access to <b>RIDE BNB</b>, a dynamic
                community-building platform where innovation meets simplicity.
                Our platform ensures a smooth, intuitive journey, making it
                easier than ever to connect, grow, and thrive within a
                supportive community dedicated to your success.
              </p>

              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    No Document Required
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Fully and truly decentralised platform
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Totally ownership free
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                Join us now and secure your financial future with our innovative
                platform.
              </p>
            </div>
            <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="feature-1.png"
              alt="dashboard feature image"
            />
          </div>

          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="feature-2.png"
              alt="feature image 2"
            />
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                Distinctive features of our platform -
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                At <b>RIDE BNB</b>, we are committed to providing unparalleled
                business opportunities that help our users grow and secure their
                futures. Our innovative helping platform connects individuals
                with a community of investors.
              </p>

              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Very negligible entry/ start up cost ( 0.0044 BNB )
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    100% instant disbursal of rewards & profits
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Earn upto 35000 BNB
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    No team building compulsory
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Huge passive income/profits
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-[#14151A] transition-colors duration-1000">
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div className="col-span-2 mb-8">
            <p className="text-lg font-medium text-purple-600 dark:text-purple-500">
              Trusted Worldwide
            </p>
            <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">
              Globally Trusted, Valued by Thousands -
            </h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              At <b>RIDE BNB</b>, we’re committed to dismantling barriers and
              fostering a more inclusive financial landscape. Join us to embark
              on the path to a brighter, financially independent future.
            </p>
          </div>
          <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f0b90b"
                  d="M6.544 5.061L12 2l5.455 3.061l-2 1.133L12 4.261L8.55 6.194zm10.911 3.867l-2-1.134L12 9.728L8.55 7.794L6.544 8.928v2.26l3.445 1.934v3.861l2.01 1.134l2.006-1.134v-3.86l3.45-1.934zm0 6.128v-2.267l-2 1.133v2.261zm1.428.8l-3.45 1.927v2.267l5.456-3.067v-6.122l-2.006 1.128zm-2.005-8.862l2 1.134v2.26l2.01-1.127V6.994l-2.005-1.133L16.878 7zm-6.89 11.612v2.26L12 22l2.005-1.133V18.61L12 19.74l-2.006-1.134zM6.545 15.05l2 1.133v-2.26l-2-1.134v2.267zM9.99 6.994L12 8.128l2.006-1.134L12 5.861L9.994 7zM5.116 8.128l2.006-1.134l-2-1.133L3.11 7v2.267l2.005 1.128zm0 3.861L3.11 10.861v6.122l5.455 3.067v-2.272L5.122 15.85v-3.867z"
                />
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                OP BNB
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Empowering decentralized finance with efficiency and scalability
                on OPBNB L2 Chain.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f0b90b"
                  d="M6.544 5.061L12 2l5.455 3.061l-2 1.133L12 4.261L8.55 6.194zm10.911 3.867l-2-1.134L12 9.728L8.55 7.794L6.544 8.928v2.26l3.445 1.934v3.861l2.01 1.134l2.006-1.134v-3.86l3.45-1.934zm0 6.128v-2.267l-2 1.133v2.261zm1.428.8l-3.45 1.927v2.267l5.456-3.067v-6.122l-2.006 1.128zm-2.005-8.862l2 1.134v2.26l2.01-1.127V6.994l-2.005-1.133L16.878 7zm-6.89 11.612v2.26L12 22l2.005-1.133V18.61L12 19.74l-2.006-1.134zM6.545 15.05l2 1.133v-2.26l-2-1.134v2.267zM9.99 6.994L12 8.128l2.006-1.134L12 5.861L9.994 7zM5.116 8.128l2.006-1.134l-2-1.133L3.11 7v2.267l2.005 1.128zm0 3.861L3.11 10.861v6.122l5.455 3.067v-2.272L5.122 15.85v-3.867z"
                />
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                BNB
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Efficient coin transactions facilitated seamlessly through
                OPBNB's infrastructure.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 256 256"
              >
                <g fill="none">
                  <rect width="256" height="256" fill="#65afff" rx="60" />
                  <path
                    fill="#000"
                    d="m165.536 25l-37.53 58.79H53L90.503 25z"
                    opacity="0.45"
                  />
                  <path
                    fill="#000"
                    d="M128.006 83.79h75.033L165.536 25H90.503z"
                    opacity="0.6"
                  />
                  <path
                    fill="#000"
                    d="m90.503 142.557l37.503-58.767L90.503 25L53 83.79z"
                    opacity="0.8"
                  />
                  <path
                    fill="#000"
                    d="m90.867 230.742l37.529-58.79h75.033l-37.53 58.79z"
                    opacity="0.45"
                  />
                  <path
                    fill="#000"
                    d="M128.396 171.952H53.363l37.503 58.79H165.9z"
                    opacity="0.6"
                  />
                  <path
                    fill="#000"
                    d="m165.899 113.185l-37.503 58.767l37.503 58.79l37.529-58.79z"
                    opacity="0.8"
                  />
                </g>
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                Smart Contract
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Automating agreements securely with transparent blockchain smart
                contracts.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="#9b1c1c"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5.636 5.636a9 9 0 1 0 12.728 12.728M5.636 5.636a9 9 0 1 1 12.728 12.728M5.636 5.636L12 12l6.364 6.364"
                />
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                No Admin
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Decentralized governance ensures autonomy and transparency
                without administrators.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ROADMAP */}
      <div className="items-center dark:bg-[#1e2026] p-4 md:p-8 gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col-reverse">
        <div className="text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Our Ecosystem Roadmap -
          </h2>
          <p className="mb-8 font-light lg:text-xl">
            Once we have a substantial user base on our platform, we will introduce a
            range of blockchain applications, gaming projects, tokens, exchanges, and
            Web 3.0 initiatives.
          </p>
          <p className="mb-8 font-light lg:text-xl">
            These projects will provide our users with ongoing, mutually rewarding
            profits and benefits from the community. Our disruptive business model is
            designed to create win-win situations, fostering exceptional growth for
            everyone involved.
           </p>
        </div>
        <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="dex.webp"
              alt="dashboard feature image"
            />
      </div>

      <section className="bg-gray-50 dark:bg-[#1e2026]">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
          <figure className="max-w-screen-md mx-auto">
            <svg
              className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <blockquote>
              <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">
                "Success begins with a single step. Believe in your potential,
                seize opportunities, and never give up. Your dedication and
                perseverance will turn dreams into reality. Start your journey
                to greatness today!"
              </p>
            </blockquote>
          </figure>
        </div>
      </section>

      <footer className="bg-white dark:bg-[#14151A]">
        <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
          <div className="text-center">
            <a
              href="#"
              className="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img
                src="logo192.png"
                className="h-6 mr-3 sm:h-9"
                alt="Landwind Logo"
              />
              <span className="text-yellow-500">RIDE</span>BNB
            </a>
            <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
              © 2024. All Rights Reserved. Built with passion ❤️ on{" "}
              <a
                target="_blank"
                href="https://opbnbscan.com/"
                className="text-yellow-500 hover:underline dark:text-yellow-500"
              >
                opBNB blockchain
              </a>
            </span>
            <ul className="flex justify-center mt-5 space-x-5">
              <li>
                <a className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
              <li>
                <a className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Homepage
