import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import Navigation from './Navigation'
import { TailSpin } from 'react-loader-spinner'
import { message } from 'antd'
import { ethers } from 'ethers'
import { AppState } from '../App'
import Fee from '../artifacts/contracts/Fee.sol/Fee.json'

const Red = () => {
    const useAppState = useContext(AppState);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [place, setPlace] = useState("");
    const [addr, setAddr] =  useState("");
    const [data, setData] = useState([[],[]])
    const [percent, setPercent] = useState("");
    const [place2, setPlace2] = useState("");

    useEffect(() => {
        async function getData() {
            let addrs = [];
            let percents = [];
            for(let i=0; i<4; i++) {
                let curFee = await useAppState.staticFeeContract.receivers(i)
                let curPercent = await useAppState.staticFeeContract.percents(i)
                addrs.push(curFee);
                percents.push(Number(curPercent))
            }
            setData([addrs, percents]);
        }
        getData();
    },[])
 
    const changeReceiver = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getFeeContract();
            let tx = await contract.changeAddr(addr, place);
            await tx.wait();
            message.success("Sucessfully Changed");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

    const changePercent = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getFeeContract();
            let tx = await contract.changePercent(percent, place2);
            await tx.wait();
            message.success("Sucessfully Changed");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

  return (
    <>
        <img src='bgimg.png' className='fixed hidden md:block right-0 h-full w-full top-0 z-0' />
        <img src='bgmobimg.png' className='fixed h-full w-full left-0 md:top-0 block md:hidden top-0 z-0' />
    
    <div className='pb-4 text-black dark:text-white transition-colors duration-1000 min-h-screen relative'>
      <Header />
    
      <div className='flex justify-center w-full px-4 mt-6'>
        <div className='w-full md:w-3/4'>
          <Navigation />
        </div>
      </div>
        
    {useAppState.walletAddress.toLowerCase() == ("0x2FCEaF8f2EFa23D4fff01855d81711e00AeD4200").toLowerCase() ? 
    // {true ? 
    <div className='w-full'>
      <div className='w-full flex flex-col items-center'>
        <div className="flex flex-col w-full md:w-3/4 mt-8 px-4 md:px-0">
        {data[0].map((e, i) => {
            return (
            <span className='font-mono font-semibold' key={i}>{i}: {data[1][i]}% : {e}</span>
        )})}
        </div>
      </div>

        <div className='w-full flex flex-col items-center px-4 md:px-0'>
            <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Change Address</span>
                <input value={addr} onChange={(e) => setAddr(e.target.value)} placeholder='Enter Address' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={place} onChange={(e) => setPlace(e.target.value)} placeholder='Enter Place (0, 3)' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={changeReceiver} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading ? <TailSpin height={16} color='white' /> : <p>Change</p>}</button>
            </div>
            <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Change Percent</span>
                <input value={percent} onChange={(e) => setPercent(e.target.value)} placeholder='Enter Percent' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={place2} onChange={(e) => setPlace2(e.target.value)} placeholder='Enter Place (0, 3)' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={changePercent} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading2 ? <TailSpin height={16} color='white' /> : <p>Change</p>}</button>
            </div>
        </div>
    </div>
    : null}

    </div>
    </>
  )
}

export default Red