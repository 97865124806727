import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import Navigation from './Navigation'
import { TailSpin } from 'react-loader-spinner'
import { message } from 'antd'
import { ethers } from 'ethers'
import { AppState } from '../App'
import Fee from '../artifacts/contracts/Fee.sol/Fee.json'
import { useTimer } from 'react-timer-hook'

function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
  }

const Admin = () => {
    const useAppState = useContext(AppState);
    const [id, setId] = useState("");
    const [addr, setAddr] = useState("");
    const [amt, setAmt] = useState("");
    const [loading2, setLoading2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [loading6, setLoading6] = useState(false);
    const [loading7, setLoading7] = useState(false);
    const [loading8, setLoading8] = useState(false);
    const [referrer, setReferrer] = useState("");
    const [acc, setAcc] = useState("");
    const [acc2, setAcc2] = useState("");
    const [id2, setId2] = useState("");
    const [id3, setId3] = useState("");
    const [id4, setId4] = useState("");
    const [level, setLevel] = useState("");
    const [pendingRoyaltyUsers, setPendingRoyaltyUsers] = useState([0, 0, 0, 0, 0, 0])
    const [pendingRoyaltyIds, setPendingRoyaltyIds] = useState([[0], [0], [0], [0], [0], [0]])

    useEffect(() => {
        async function getData() {
            let arr = [0, 0, 0, 0, 0, 0];
            let idArr = [[], [], [], [], [], []];
            setPendingRoyaltyIds(idArr);
            for(let i=0; i<4; i++) {
                let _data = await useAppState.staticContract.getPendingRoyaltyUsers(i)
                arr[i] = Number(_data.length);
                for(let j=0; j<_data.length; j++) {
                    idArr[i].push(Number(_data[j]))
                }
            }
            setPendingRoyaltyUsers(arr);
            setPendingRoyaltyIds(idArr);
        }
        getData();
    },[])

    const newRegister = async () => {
        setLoading3(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.register(referrer, acc);
            await tx.wait();
            message.success("Sucessfully Registered");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading3(false);
    }

    const upgrade = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.upgrade(id2, level);
            await tx.wait();
            message.success("Sucessfully Upgraded");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

    const distribute = async () => {
        setLoading4(true);
        try {
            let provider = new ethers.BrowserProvider(window.ethereum);
            let signer = await provider.getSigner();
            let contract = new ethers.Contract(useAppState.feeAddress, Fee.abi, signer);

            let tx = await contract.distribute();
            await tx.wait();
            message.success("Sucessfully Distributed");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading4(false);
    }

    const checkID = async () => {
      try {
        let _id = await useAppState.staticContract.id(id3);
        window.alert(Number(_id));
      } catch (error) {
          message.error(error.reason);
      }
    }

    const changeID = async () => {
        setLoading5(true);
        try {
            let contract =  await useAppState.getContract();
  
            let tx = await contract.setAddr(acc2, 2, id4);
            await tx.wait();
            message.success("Sucessfully Updated");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading5(false);
    }

    const renounce = async () => {
      setLoading6(true);
      try {
          let contract =  await useAppState.getContract();

          let tx = await contract.renounceOwnership();
          await tx.wait();
          message.success("Sucessfully Renounced");
      } catch (error) {
          message.error(error.reason);
      }
      setLoading6(false);
  }

    const updateRoyaltyUsers = async (i) => {
      setLoading7(true);
      try {
          let contract =  await useAppState.getContract();
          let tx = await contract.movePendingRoayltyUsers(i);
          await tx.wait();
          message.success("Sucessfully Updated");
      } catch (error) {
          message.error(error.reason);
      }
      setLoading7(false);
  }

  return (
    <div className='pb-4 text-black dark:text-white transition-colors duration-1000 min-h-screen'>
      <Header />
    
      <div className='flex justify-center w-full px-4 mt-6'>
        <div className='w-full md:w-3/4'>
          <Navigation />
        </div>
      </div>

    {useAppState.walletAddress.toLowerCase() == ("0x61653a0101867d32220dC7c6E8214808670e81e0").toLowerCase() ? 
    <div className='w-full'>
      <div className='w-full flex flex-col items-center'>
        <div className="flex flex-col w-full md:w-3/4 mt-8 px-4 md:px-0">
        <span className='font-mono font-semibold'>Fee Contract Balance: {useAppState.bal2} BNB</span>
        <span className='font-mono font-semibold'>Royalty Contract Balance: {useAppState.bal3} BNB</span>
        </div>
      </div>

        <div className='w-full flex flex-col items-center px-4 md:px-0'>
            <div className="flex flex-col w-full md:w-3/4 mt-8">
                <span className='font-mono font-semibold'>Distribute Admin Fee : {useAppState.bal2} BNB</span>
                <button onClick={distribute} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading4 ? <TailSpin height={16} color='white' /> : <p>Distribute Fee</p>}</button>
            </div>

            <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Register</span>
                <input value={referrer} onChange={(e) => setReferrer(e.target.value)} placeholder='Enter Referrer' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={acc} onChange={(e) => setAcc(e.target.value)} placeholder='Enter Address' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={newRegister} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading3 ? <TailSpin height={16} color='white' /> : <p>Register</p>}</button>
            </div>

            <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Upgrade</span>
                <input value={id2} onChange={(e) => setId2(e.target.value)} placeholder='Enter ID' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={level} onChange={(e) => setLevel(e.target.value)} placeholder='Enter Level' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={upgrade} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading ? <TailSpin height={16} color='white' /> : <p>Upgrade</p>}</button>
            </div>

            {/* <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Withdraw</span>
                <input value={addr} onChange={(e) => setAddr(e.target.value)} placeholder='Enter ID' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={level} onChange={(e) => setLevel(e.target.value)} placeholder='Enter Level' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={upgrade} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading ? <TailSpin height={16} color='white' /> : <p>Upgrade</p>}</button>
            </div> */}

            <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>Get ID</span>
                <input value={id3} onChange={(e) => setId3(e.target.value)} placeholder='Enter Address' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={checkID} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{<p>Get ID</p>}</button>
            </div>

            <div className="flex flex-col w-full md:w-3/4 mt-4">
                <span className='font-mono font-semibold'>ID Change</span>
                <input value={id4} onChange={(e) => setId4(e.target.value)} placeholder='Enter ID' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={acc2} onChange={(e) => setAcc2(e.target.value)} placeholder='Enter New Address' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={changeID} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading5 ? <TailSpin height={16} color='white' /> : <p>Change</p>}</button>
            </div>

            {/* <div className="flex flex-col w-full md:w-3/4 mt-4">
                <button onClick={renounce} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading6 ? <TailSpin height={16} color='white' /> : <p>Renounce Ownership</p>}</button>
            </div> */}

            <div className="flex flex-col w-full md:w-3/4 mt-4">
            <p>Pending Royalty Users</p>
            {useAppState.ranks.map((e, i) => {
                return (
                    i >= 9 ?
                    <div className='mt-2'> 
                        {pendingRoyaltyIds[i - 9].map((id, i) => {
                            return (
                                <p>{e} {i+1} - {id}</p>
                            )
                        })}
                    <button onClick={() => updateRoyaltyUsers(i - 9)} className='bg-green-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading7 ? <TailSpin height={16} color='white' /> : <p>Update {e} - {pendingRoyaltyUsers[i - 9]}</p>}</button>
                    </div>
                    : null
                )
            })}
            </div>

        </div>
    </div>
    : null}

    </div>
  )
}

export default Admin