import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App'
import { TailSpin } from 'react-loader-spinner';
import { message } from 'antd';
import { useTimer } from 'react-timer-hook';
import Header from './Header';
import Navigation from './Navigation';

function MyTimer({ expiryTimestamp }) {
    const {
        seconds,
        minutes,
        hours,
        days
    } = useTimer({ expiryTimestamp });

    return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Royalty = () => {
    const useAppState = useContext(AppState);
    const [user, setUser] = useState({
        level: 0,
        income: 0,
        matrixTeam: 0,
        directTeam: 0,
        royaltyIncome: 0,
        referralIncome: 0,
        levelIncome: 0,
        lostIncome: 0,
        totalDeposit: 0
    })
    const [showRoyalty, setShowRoyalty] = useState(false);
    const [roayltyTime, setRoyaltyTime] = useState(0);
    const [royalty, setRoyalty] = useState([0, 0, 0]);
    const [royaltyPrev, setRoyaltyPrev] = useState([0, 0, 0, 0]);
    const [royaltyUsers, setRoyaltyUsers] = useState([[0], [0], [0]]);
    const [totalRoyaltyUsers, setTotalRoyaltyUsers] = useState([0, 0, 0, 0]);
    const [isRoyaltyAvl, setIsRoyaltyAvl] = useState(false);
    const [royaltyIndex, setRoyaltyIndex] = useState(false);
    const [loading4, setLoading4] = useState(false);

    useEffect(() => {
        async function getData() {
            let _user = await useAppState.staticContract.userInfo(useAppState.id);
            let _lost = await useAppState.staticContract.lostIncome(useAppState.id);
            setUser({
                level: Number(_user.level),
                income: useAppState.convert(_user.totalIncome),
                matrixTeam: Number(_user.totalMatrixTeam),
                directTeam: Number(_user.directTeam),
                royaltyIncome: useAppState.convert(_user.royaltyIncome,),
                referralIncome: useAppState.convert(_user.referralIncome,),
                levelIncome: useAppState.convert(_user.levelIncome),
                lostIncome: useAppState.convert(_lost),
                totalDeposit: useAppState.convert(_user.totalDeposit)
            })

            let _royaltyTime = await useAppState.staticContract.getRoyaltyTime();
            setRoyaltyTime(Number(_royaltyTime));
            setShowRoyalty(true);

            let curDay = await useAppState.staticContract.getCurRoyaltyDay();
            let _royalty = await useAppState.staticContract.royalty(curDay, 0);
            let _royalty1 = await useAppState.staticContract.royalty(curDay, 1);
            let _royalty2 = await useAppState.staticContract.royalty(curDay, 2);
            let _royalty3 = await useAppState.staticContract.royalty(curDay, 3);
            setRoyalty([useAppState.convert(_royalty), useAppState.convert(_royalty1), useAppState.convert(_royalty2), useAppState.convert(_royalty3)]);

            if (Number(curDay) > 0) {
                let _royalty = await useAppState.staticContract.royalty(Number(curDay) - 1, 0);
                let _royalty1 = await useAppState.staticContract.royalty(Number(curDay) - 1, 1);
                let _royalty2 = await useAppState.staticContract.royalty(Number(curDay) - 1, 2);
                let _royalty3 = await useAppState.staticContract.royalty(Number(curDay) - 1, 3);
                setRoyaltyPrev([useAppState.convert(_royalty), useAppState.convert(_royalty1), useAppState.convert(_royalty2), useAppState.convert(_royalty3)]);
            }

            let _rUsers = [];
            for (let i = 0; i < 4; i++) {
                _rUsers.push(Number(await useAppState.staticContract.royaltyUsers(i)));
            }

            setTotalRoyaltyUsers(_rUsers)

            setIsRoyaltyAvl(false);
            for (let i = 0; i < 4; i++) {
                let isAvl = await useAppState.staticContract.isRoyaltyAvl(useAppState.id, i);
                if (isAvl) {
                    setIsRoyaltyAvl(true);
                    setRoyaltyIndex(i);
                }
            }
        }
        getData();
    }, [useAppState.walletAddress, useAppState.id, useAppState.change])

    const claimRoyalty = async () => {
        setLoading4(true);
        try {
            const contract = await useAppState.getContract();
            let tx = await contract.claimRoyalty(user.level - 10);
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            message.success("Sucessfully Claimed");
        } catch (error) {
            message.error(error.reason)
            console.log(error)
        }
        setLoading4(false);
    }

    return (
        <>
            <img
                src="bgimg.png"
                className="fixed hidden md:block right-0 top-0 z-0 opacity-100 w-full h-full"
            />
            <img
                src="bgmobimg.png"
                className="fixed w-full left-0 md:top-0 block md:hidden top-0 z-0 opacity-100"
            />

            <div className="pb-4 text-black dark:text-white transition-colors duration-1000 min-h-screen relative">
                <Header />

                <div className="flex justify-center w-full px-4 mt-6">
                    <div className="w-full md:w-3/4">
                        <Navigation />
                    </div>
                </div>

                <div className="flex justify-center px-4 md:p-0 mt-6">
                    <div className="md:w-3/4 w-full">
                        <div className="w-full flex flex-col justify-center items-center bg-whtie dark:bg-[#1e2026] dark:bg-opacity-40 rounded-lg shadow-lg p-4">
                            <p className="text-white py-3 px-6 font-bold text-xl flex flex-col justify-center">
                                <span className='w-full text-center'>Daily Royalty Countdown</span>
                            </p>
                            {showRoyalty ? (
                                <p className="text-2xl mt-2 font-mono px-3 rounded-sm text-white font-bold">
                                    {<MyTimer expiryTimestamp={roayltyTime * 1000} />}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center px-4 md:p-0 mt-6">
                    <div className="md:w-3/4 w-full">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {['Icon', 'Legend', 'Emperor', 'Conqueror'].map(
                                (e, i) => (
                                    <div
                                        key={i}
                                        className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-lg flex flex-col justify-between"
                                    >
                                        <h4 className="text-lg font-bold text-yellow-400 mb-2 text-center">
                                            {e}
                                        </h4>
                                        <div className="bg-gray-700 bg-opacity-60 p-2 rounded-md text-center">
                                            <p className="text-white text-sm font-semibold">Total Users</p>
                                            <span className="text-xl font-bold text-white">
                                                {totalRoyaltyUsers[i]}
                                            </span>
                                        </div>
                                        <div className="mt-4">
                                            <p className="text-sm text-gray-300 text-center">Today</p>
                                            <p className="text-lg font-bold text-center text-yellow-400">
                                                {royalty[i]} BNB
                                            </p>
                                            <p className="text-sm text-gray-300 mt-2 text-center">
                                                Previous Day
                                            </p>
                                            <p className="text-lg font-bold text-center text-yellow-400">
                                                {royaltyPrev[i]} BNB
                                            </p>
                                        </div>
                                        {isRoyaltyAvl && i === user.level - 10 && royaltyPrev[user.level - 10] > 0 && (
                                            <button
                                                onClick={claimRoyalty}
                                                className="flex justify-center items-center mt-4 bg-lime-500 text-white text-sm px-4 py-2 rounded-md hover:bg-lime-600 transition-all"
                                            >
                                                {loading4 ? <TailSpin color="white" height={14} /> : 'Claim'}
                                            </button>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Royalty